import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from '@mui/material';
import {
  DirectDebitIcon,
  InvoiceIcon,
  SuccessIndicatorIcon,
} from "../../../../../Assets/Images";
import {
  FormControlKeys,
} from "../../../../../Components/FormControls";
import FormikControl from "../../../../../Components/Formik/FormikControl";
import {
  DivContainer,
  FieldSetHeader,
  FlexContainer,
  InlineBlockColMd6AndAlignTopSection,
  FormContainer,
  HoverableLink,
  Margin5LeftSDiv,
} from "../../../../../Components/Formik/StyledComponents";
import {
  convertEnumToSelectListWithIntegerValue,
  getDayAndMonthDate,
  getDropdownSalesPersonOptions,
  getFormattedDateWithYearAndTime,
} from "../../../../../Utils";
import { JobStatus, PaymentMethod } from "../../../../../Utils/Enums";
import { EditJobKeys, StatusInformationJobKeys } from "../Types";
import {
  useGetAdminUsersForDropdown,
  usePutReviveJob,
  usePutUnlockJob,
  usePutUpdateJobStatus,
  usePutUpdateJobAdminUser
} from "../Hooks";
import {
  DangerSpanItem,
  LabelItem,
  SpanBoldItem,
  SpanItem,
  UnlockJobHeader,
} from "../../../../../Components/UI";
import {
  JobViewModel,
  ReviveJobModel,
  JobsVehicle,
  UpdateJobStatusModel
} from "../../../../../Models/Jobs/EditJob";
import { UpdateJobAdminUserModel } from '../../../../../Models/Jobs/ViewJobs/AssignJobModels';
import { Alert, ModalDialog, ModalDialogProps, useAlert } from "../../../../../Components";
import { Col, Row } from "react-bootstrap";
import ReviveModule from "./Modules/ReviveModule";
import { usePutRelistJob } from "../Hooks/usePutRelistJob";
import UnlockJobModal from "./Modules/UnlockJobModal";
import { JobLockedReason, getJobReason } from "../../../../../Utils/Enums/JobLockedReason";
import { RouteConstants } from "../../../../../Routes";
import JobActions from "./JobActions";

const Section2StatusInformation: React.FC<GeneralFormProps> = (
  { job, showAlert, refetchJob }
): JSX.Element => {

  const handleModalClose = () => {
    setModalContent({ ...modalContent, show: false });
  };
  
  /** useStates */
  const [formikInitialValues, setFormikInitialValues] =
    useState<JobViewModel>(job);

  /** Custom Hooks */
  const { alert, setAlert, handleAlertClose } = useAlert();

  const {
    mutate: putUpdateJobAdminUserMutate,
    data: putUpdateJobAdminUserResponse,
    error: putUpdateJobAdminUserError,
  } = usePutUpdateJobAdminUser();

  const {
    mutate: putUnlockJobMutate,
    data: putUnlockJobDataResponse,
    error: putUnlockJobDataError,
  } = usePutUnlockJob();

  const {
    mutate: putRelistJob,
    error: relistJobError,
    data: relistJobSuccess,
  } = usePutRelistJob();

  const {
    mutate: putUpdateJobStatusMutate,
    data: putUpdateJobStatusResponse,
    error: putUpdateJobStatusError,
  } = usePutUpdateJobStatus();

  const { 
    data: adminUsersList,
    error: getAdminUserError 
  } = useGetAdminUsersForDropdown();

  const {
    mutate: putReviveJob,
    isLoading: isPutReviveJobLoading,
    data: putReviveJobResponse,
    error: putReviveJobError,
  } = usePutReviveJob();

  /* useEffect */
  useEffect(() => {
    if(relistJobSuccess){
      showAlert(
        'Job Relisted',
        'Job '+ job.Id +' has been relisted to ' + relistJobSuccess +'.'
      );
    }
  }, [relistJobSuccess]);

  useEffect(() => {
    if(putUnlockJobDataResponse){
      showAlert(
        "Job Unlocked", 
        putUnlockJobDataResponse,
        refetchJob()
      )
    }
  }, [putUnlockJobDataResponse]);

  useEffect(() => {
    if(putUpdateJobAdminUserResponse){
      showAlert(
        'Sales Person Updated',
        'Job Sales Person has been updated'
      );
    }
  }, [putUpdateJobAdminUserResponse]);

  useEffect(() => {
    if (putUpdateJobAdminUserError) {
      showAlert(
        'Update Sales Person Error',
        putUpdateJobAdminUserError.Description
      );
    }
  }, putUpdateJobAdminUserError)

  useEffect(() => {
    if (putUpdateJobStatusResponse) {
      showAlert(
        'Job Status Updated',
        'The job status was updated successfully'
      );
    }
  }, [putUpdateJobStatusResponse])

  useEffect(() => {
    if (putUpdateJobStatusError) {
      showAlert(
        'Update Job Status Error',
        putUpdateJobStatusError.Description
      );
    }
  }, [putUpdateJobStatusError])

  useEffect(() => {
    setFormikInitialValues(job);
  }, [job])
 
  /** Methods */
  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: () => {},
    enableReinitialize: true,
  });
  const { values } = formik;

  const getPaymentMethodImage = (params?: number | null) => {
    switch (params) {
      case PaymentMethod.Invoice:
        return (
          <Tooltip title="Invoice">
            <img alt="Invoice" src={InvoiceIcon} />
          </Tooltip>
        );
      case PaymentMethod.PrePay:
        return (
          <Tooltip title="Pre pay">
            <img alt="Pre pay" src={SuccessIndicatorIcon} />
          </Tooltip>
        );
      case PaymentMethod.SmartDebit:
        return (
          <Tooltip title="Direct Debit">
            <img alt="Direct Debit" src={DirectDebitIcon} />
          </Tooltip>
        );
      case PaymentMethod.Pending:
        return <React.Fragment />;
    }
  };

  const PaymentMethodImage = getPaymentMethodImage(job.PaymentMethod);

  const handlePutUpdateJobStatus = (jobStatus?: number) => {
    const payload: UpdateJobStatusModel = {
      Id: job.Id,
      Status: jobStatus,
    };
    putUpdateJobStatusMutate(payload);
  };

  const handlePutUpdateJobAdminUser = (adminUserID?: number) => {
    if (adminUserID) {
      const payload: UpdateJobAdminUserModel = {
        JobId: job.Id,
        AdminUserID: adminUserID,
      };
      putUpdateJobAdminUserMutate(payload);
    }
  };

  const handlePutUnlockJob = () => {
    if (job.ID) {
      putUnlockJobMutate(job.ID);
    }
  };

  const [reviveJobFormValues, setReviveJobFormValues] = useState<ReviveJobModel>({
    JobId: 0,
    UseExactCollectionDateAndTime: false,
    CollectionDate: undefined,
    CollectionTime: 0,
    UseExactDeliveryDateAndTime: false,
    DeliveryDate: undefined,
    DeliveryTime: 0,
  });

  const handleReviveJobDetailsChange = (values: ReviveJobModel) => {
    setReviveJobFormValues(values);
  };

  useEffect(() => {
    reviveJobFormValuesRef.current = reviveJobFormValues;
  }, [reviveJobFormValues]);
  
  const reviveJobFormValuesRef = useRef(reviveJobFormValues);
  
  const handlePutReviveJob = async () => {
    const reviveValues = reviveJobFormValuesRef.current;
    if (reviveValues) {
      if (reviveValues.CollectionDate && reviveValues.DeliveryDate) {
        reviveValues.JobId = job.Id;
        putReviveJob(reviveValues);
      }
    }
  }

  useEffect(() => {
    if (putReviveJobResponse) {
      setAlert({
        ...alert,
        show: true,
        header: 'Job Revived', 
        body: "Job revived successfully.",
        closeCallback: () => {
          handleAlertClose();
          refetchJob();
        }
      });
      
    }
  }, [putReviveJobResponse]);
  
  const [modalContent, setModalContent] = useState<ModalDialogProps>({
    header: "",
    body: "",
    show: false,
    closeCallback: handleModalClose,
  });

  const getReviveModuleBody = () => <ReviveModule onValuesChange={handleReviveJobDetailsChange} />;

  const getReviveFooter = () => {
    return (
      <FormikProvider value={formik}>
        <FormContainer
          onSubmit={(e) => {
            e.preventDefault();
            handlePutReviveJob();
          }}
        >
          <Row>
            <Col>
              <FormikControl
                label="Submit"
                control={FormControlKeys.Button}
                type="submit"
              />
            </Col>
            <Col>
              <FormikControl label="Close" control={FormControlKeys.Button} />
            </Col>
          </Row>
        </FormContainer>
      </FormikProvider>
    );
  };

  const showReviveModule = () => {
    setModalContent({
      ...modalContent,
      show: true,
      header: "Revive Job - Please Enter New Dates",
      body: getReviveModuleBody(),
      footer: getReviveFooter(),
    });
  };

  const getUnlockModal = () => {
    return <UnlockJobModal 
      jobId={job.Id} 
      putUnlockJob={putUnlockJobMutate} 
      closeModal={handleModalClose} />;
  };

  const showUnlockModal = () => {
    setModalContent({
      ...modalContent,
      show: true,
      header: <UnlockJobHeader>Unlock Job {job.Id}</UnlockJobHeader>,
      body: getUnlockModal(),
    });
  };

  return (
    <>
    <Alert {...alert} />
      <FormikProvider value={formik}>
        <Form style={{display: 'inline-block'}}>
          <fieldset>
            <br />
            <FieldSetHeader>Status Information</FieldSetHeader>
            <InlineBlockColMd6AndAlignTopSection>
              <ModalDialog {...modalContent}></ModalDialog>
              <div>
                <LabelItem>Request By:</LabelItem>
                <HoverableLink to={"url_view_member"} target="_blank">
                  <SpanItem>{values.RequestingMemberUserName}</SpanItem>
                </HoverableLink>
              </div>
              <div>
                <LabelItem>Contact:</LabelItem>
                <SpanItem>{values.RequestingMemberContact}</SpanItem>
              </div>
              <div>
                <LabelItem>Tel:</LabelItem>
                <SpanItem>{values.RequestingMemberLandline}</SpanItem>
              </div>
              <div>
                <LabelItem>Mob:</LabelItem>
                <SpanItem>{values.RequestingMemberMobile}</SpanItem>
              </div>
              <div>
                <LabelItem>Request Date:</LabelItem>
                <SpanItem>
                  {getDayAndMonthDate(values.RequestDate)}
                </SpanItem>
              </div>

              <div>
                <DivContainer>
                  <FlexContainer style={{ marginBottom: "10px" }}>
                    <LabelItem>Job Status:</LabelItem>
                    {values.Expired && (
                      <div>
                        <DangerSpanItem>EXPIRED!</DangerSpanItem>
                        <FormikControl
                          control={FormControlKeys.Button}
                          label="Revive"
                          onClick={() => {
                            showReviveModule();
                          }}
                        />
                      </div>
                    )}
                    {!values.Expired && (
                      <FormikControl
                        control={FormControlKeys.Select}
                        name={EditJobKeys.JobStatus}
                        options={convertEnumToSelectListWithIntegerValue(
                          JobStatus
                        )}
                      />
                    )}
                    {!values.Expired && (
                      <Margin5LeftSDiv>
                        <FormikControl
                          control={FormControlKeys.Button}
                          label="Update"
                          onClick={() => {
                            handlePutUpdateJobStatus(values.JobStatus);
                          }}
                        />
                      </Margin5LeftSDiv>
                    )}
                  </FlexContainer>
                </DivContainer>
              </div>

              <div>
                <DivContainer>
                  <FlexContainer>
                    <LabelItem>Sales Person:</LabelItem>
                    {(job.JobStatus === JobStatus.CompletedJob) &&
                      adminUsersList?.Results && (
                      <FormikControl
                        style={{ maxWidth: "220px" }}
                        control={FormControlKeys.Select}
                        name={StatusInformationJobKeys.AdminUserID}
                        options={getDropdownSalesPersonOptions(
                          adminUsersList?.Results
                        )}
                        disabled={true}
                      />
                    )}
                    {job.JobStatus !== JobStatus.CompletedJob &&
                      adminUsersList?.Results && (
                        <>
                          <FormikControl
                            style={{ maxWidth: "220px" }}
                            control={FormControlKeys.Select}
                            name={StatusInformationJobKeys.AdminUserID}
                            options={getDropdownSalesPersonOptions(adminUsersList?.Results)}
                          />
                          <Margin5LeftSDiv>
                            <FormikControl
                              control={FormControlKeys.Button}
                              label="Update"
                              onClick={() => {
                                handlePutUpdateJobAdminUser(values.AdminUserID);
                              }}
                            />
                          </Margin5LeftSDiv>
                        </>
                      )}
                  </FlexContainer>
                </DivContainer>
              </div>

              <div>
                <LabelItem>Payment Method:</LabelItem>
                <span>{(job?.PaymentMethod) ? PaymentMethod[job.PaymentMethod] : ""}</span>
                {job.PaymentMethod === PaymentMethod.PrePay && (
                  <SpanItem>{PaymentMethodImage}</SpanItem>
                )}
              </div>
              <div>
                <FlexContainer>
                  <LabelItem>Bill:</LabelItem>
                  <div>
                    {!job.BillID && <SpanItem>{"No Bill Exists Yet!"}</SpanItem>}
                    {job.BillID && (
                      <HoverableLink title='View Bill' to={`${RouteConstants.ViewBillById}/${job.BillID}`}>
                        <SpanBoldItem>View Bill</SpanBoldItem>
                      </HoverableLink>
                    )}
                  </div>
                </FlexContainer>
                {job.LockedDate && (
                  <>
                    <FlexContainer>
                    <LabelItem> Job locked on: </LabelItem>
                    <SpanItem>{getFormattedDateWithYearAndTime(job.LockedDate!)}</SpanItem>
                    </FlexContainer>
                    {job.LockedReason && (
                        <FlexContainer>
                              <LabelItem> Locked Reason: </LabelItem>
                              <SpanItem>{getJobReason(parseInt(job.LockedReason))}</SpanItem>
                        </FlexContainer>
                    )}
                    {job.LockedReason == JobLockedReason.JobClosed.toString() && (
                      <FlexContainer>
                        <FormikControl
                              control={FormControlKeys.Button}
                              label='Unlock Job'
                              variant='danger'
                              onClick={() => {
                                  showUnlockModal();
                              }}
                        />
                      </FlexContainer>
                    )}
                  </>
                )}
              </div>
            </InlineBlockColMd6AndAlignTopSection>
            <JobActions
              job={job}
              showAlert={showAlert}
              refetchJob={refetchJob}
            />

          </fieldset>
        </Form>
      </FormikProvider>
    </>
  );
};

type GeneralFormProps = {
  job: JobViewModel;
  showAlert: Function;
  refetchJob: Function;
  vehicles: JobsVehicle[];
};

export default Section2StatusInformation;
